import {Injectable, OnDestroy} from '@angular/core';
import {HttpService} from "@revolve-app/app/core/services/network/http/http.service";
import {TCreateAccountModel, TUpdateAccountModel} from "@revolve-app/app/core/metadata/types/account.models";
import {MicroService} from "@revolve/meta-data/metadata/enums/MicroService";
import {Observable} from "rxjs";
import {AccountMeResponse, AccountMinimalResponse} from "@revolve/meta-data/roots/account-microservice/metadata.errors";
import {env} from "@revolve-app/env/env";

@Injectable()
export class AccountService{
  private baseUrl = `api/gateway/${MicroService.ACCOUNT}/${env.backendVersion}`;

  constructor(private httpService: HttpService) {
  }


  list(queryParams: any){
    return this.httpService.get(`${this.baseUrl}/account/list`, {...queryParams})
  }

  getById(id:string){
    return this.httpService.get(`${this.baseUrl}/account/${id}`, {}, )
  }
  me(): Observable<AccountMeResponse> {
    return this.httpService.get(`${this.baseUrl}/me`, {})
  }

  update(id: string, data: TUpdateAccountModel){
    return this.httpService.put(`${this.baseUrl}/account/${id}`, {...data})
  }

  updateMePassword(data: any){
    return this.httpService.put(`${this.baseUrl}/me/reset-password`, {...data})
  }
  updateMeProfile(data: any){
    return this.httpService.put(`${this.baseUrl}/me/update`, {...data})
  }

  delete(id: string){

    return this.httpService.delete(`${this.baseUrl}/account/${id}`)
  }

  create(data: TCreateAccountModel){
    return this.httpService.post(`${this.baseUrl}/account/create`, {...data})
  }
}
